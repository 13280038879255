import { useEffect } from 'react';

import { AddonsConfig, AddonTypes } from '~/app/lib/songwhipApi/types';
import { DEFAULT_TERRITORY_CODE } from './ItemPageEdit/addons/ageGate';
import useFetchSessionUser from '~/app/lib/hooks/useFetchSessionUser';
import { selectUserCountry } from '~/app/lib/store/session/selectors';
import { resolveAgeFromTimestamp } from '~/app/lib/utils/date';
import { GateTypes } from '~/app/pages/ItemGatePage/types';
import { getClientCookie } from '~/app/lib/utils/cookie';
import { useSelector } from '~/app/lib/store/redux';
import { useAppRouter } from '~/app/lib/router2';
import { DOB_COOKIE_NAME, EXCLUSIVE_CONTENT_COOKIE_NAME } from '~/config';

import { useCountryBlocked } from './useCountryBlocked';
import { ItemContext } from './types';

export const usePageGate = ({
  data: { item },
  addons: { AGE_GATE, EXCLUSIVE_CONTENT },
}: ItemContext) => {
  const country = useSelector(selectUserCountry);
  const isCountryBlocked = useCountryBlocked();
  const { isLoggedIn } = useFetchSessionUser();
  const router = useAppRouter();

  const { isAgeBlocked, isAgeGated } = AGE_GATE
    ? getIsAgeGated(AGE_GATE, country)
    : { isAgeGated: false, isAgeBlocked: false };

  const isExclusiveContentGated = EXCLUSIVE_CONTENT
    ? getIsExclusiveContentGated(EXCLUSIVE_CONTENT, item.pagePath)
    : false;

  const isPageGated =
    isCountryBlocked || isAgeBlocked || isAgeGated || isExclusiveContentGated;

  useEffect(() => {
    if (isLoggedIn) return;

    if (isPageGated) {
      // Order means priority
      const resolveGateType = () => {
        if (isCountryBlocked) {
          return GateTypes.COUNTRY_BLOCKED;
        }

        if (isAgeBlocked) {
          return GateTypes.AGE_BLOCKED;
        }

        if (isAgeGated) {
          return GateTypes.AGE_GATE;
        }

        if (isExclusiveContentGated) {
          return GateTypes.EXCLUSIVE_CONTENT;
        }
      };

      router.replace(`${item.pagePath}/gate?type=${resolveGateType()}`);
    }
  }, [isLoggedIn, isPageGated]);

  return isPageGated;
};

const getIsAgeGated = (
  addon: NonNullable<AddonsConfig[AddonTypes.AGE_GATE]>,
  country: string
) => {
  const ageLimit = addon[country] ?? addon[DEFAULT_TERRITORY_CODE];

  const userAge = process.browser
    ? resolveAgeFromTimestamp(Number(getClientCookie(DOB_COOKIE_NAME)))
    : undefined;

  return {
    isAgeGated: ageLimit && userAge === undefined,
    isAgeBlocked: ageLimit && userAge && userAge < ageLimit,
  };
};

const getIsExclusiveContentGated = (
  addon: NonNullable<AddonsConfig[AddonTypes.EXCLUSIVE_CONTENT]>,
  pagePath: string
) => {
  const cookiePagePath = process.browser
    ? getClientCookie(EXCLUSIVE_CONTENT_COOKIE_NAME)
    : undefined;

  return Boolean(addon) && pagePath !== cookiePagePath;
};
