import css from 'styled-jsx/css';

import { WithSpacingProps } from '~/app/lib/hocs/withSpacing';

import LogoSongwhip from '../Logos/LogoSongwhip';
import Loading from '../Loading';
import Box from '../Box';

import ImageBackground from './Background/ImageBackground';

const styles = css.resolve`
  .root {
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
    background: rgba(0, 0, 0, 0.3);
  }
`;

export const ItemBlockedOverlay = ({
  testId = 'itemBlockedOverlay',
  isLoading = false,
  withLogo = true,
  children,

  ...spacingProps
}: {
  children: React.ReactNode;
  isLoading?: boolean;
  withLogo?: boolean;
  testId?: string;
} & WithSpacingProps) => {
  return (
    <Box
      testId={testId}
      className={`${styles.className} root`}
      coverParent
      centerContent
    >
      <Box zIndex={-1}>
        <ImageBackground />
      </Box>
      <Box
        coverParent
        zIndex={-1}
        style={{
          background: `linear-gradient(0deg,
            #000 31.7%,
            rgba(0, 0, 0, 0.60) 52.88%,
            rgba(0, 0, 0, 0.34) 64.14%,
            rgba(0, 0, 0, 0.15) 81.22%,
            rgba(0, 0, 0, 0.40) 91.46%,
            #000 100%)`,
        }}
      />
      {isLoading ? (
        <Loading />
      ) : (
        <Box
          testId={testId}
          flexColumn
          width="32rem"
          gap="2.63rem"
          {...spacingProps}
        >
          {withLogo && <LogoSongwhip size="4.9rem" color="#fff" />}
          {children}
        </Box>
      )}
      {styles.styles}
    </Box>
  );
};
