import { OrchardBrands } from '~/app/lib/songwhipApi/types';
import toShortUrl from '~/app/lib/toShortUrl';
import { removePathSuffix } from '~/app/lib/router2/utils';

export const toDraftItemPagePath = (pagePath: string) => `/draft${pagePath}`;

export const toShareUrl = ({
  isOwned,
  isDraft,
  pageBrand,
}: {
  isOwned?: boolean;
  isDraft?: boolean;
  pageBrand?: OrchardBrands;
}) => {
  if (!process.browser) return;

  const { href, pathname, hostname } = location;

  // Custom domains don't contain songwhip.com (eg. foo.sng.to, mydomain.com).
  // This is a bit brittle, we could use a `HOST` env var here instead and
  // test location against that … meh.
  const isCustomDomain = !/songwhip\.com|localhost/.test(hostname);

  // pro pages not 'scoped' behind custom domains share the short url
  // as short-links are preferable for pro users w/o custom domains
  if (isOwned && !isCustomDomain) {
    return toShortUrl({ pagePath: pathname, pageBrand, isDraft });
  }

  // all other pages share the current url, minus search
  // to avoid re-sharing third-party clutter (eg. fbci=123abc…)
  return removePathSuffix(href);
};
