import { selectUserCountry } from '~/app/lib/store/session/selectors';
import {
  PAGES_BLOCKED_COUNTRIES,
  PRERELEASE_BLOCKED_COUNTRIES,
} from '~/app/lib/privacy';
import { BYPASS_COUNTRY_BLOCKING } from '~/config.js';
import { useSelector } from '~/app/lib/store/redux';
import { useAppRouter } from '~/app/lib/router2';

import { useItemContext } from './ItemPageContext';

export const useCountryBlocked = () => {
  const item = useItemContext();
  const country = useSelector(selectUserCountry);
  const query = useAppRouter().getQuery();

  // special query param for privacy team testing purposes
  if (query[BYPASS_COUNTRY_BLOCKING] !== undefined) {
    return false;
  }

  const { type, userCanEdit } = item.data.item;

  if (
    (type === 'artist' || type === 'track' || !item.data.item.isDraft) &&
    !userCanEdit
  ) {
    const isBlocked = PAGES_BLOCKED_COUNTRIES.includes(country);

    if (isBlocked) {
      return true;
    }
  }

  if (
    type === 'album' &&
    item.data.item.isPrerelease &&
    !item.data.item.isDraft &&
    !userCanEdit
  ) {
    return PRERELEASE_BLOCKED_COUNTRIES.includes(country);
  }

  return false;
};
