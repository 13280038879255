import * as countryCodes from '~/app/lib/country/countryCodes';

import removeUndefinedKeys from '../utils/removeUndefinedKeys';

import type { MarketingConsent, MarketingConsentCountry } from './types';

import {
  MARKETING_CONSENT_COUNTIES,
  MARKETING_CONSENT_COUNTRIES_WITHOUT_OPT_IN,
  MARKETING_DOUBLE_OPT_IN_CONFIG,
  MARKETING_OPT_IN_CONFIG,
  NO_IP_LOCATION_BASED_MARKETING,
} from './constants';

const COUNTRY_TO_LEGAL_NAME: Record<MarketingConsentCountry, string> = {
  [countryCodes.UNITED_STATES]: 'Sony Music Entertainment',
  [countryCodes.CANADA]: 'Sony Music Entertainment Canada Inc.',
  [countryCodes.BELGIUM]: 'Sony Music Entertainment Belgium N.V.',
  [countryCodes.NETHERLANDS]: 'Sony Music Entertainment Netherlands BV.',
  [countryCodes.LUXEMBOURG]: 'Sony Music Entertainment Belgium N.V./S.A.',
  [countryCodes.DENMARK]: 'Sony Music Entertainment Denmark A/S',
  [countryCodes.FINLAND]: 'Sony Music Entertainment Finland',
  [countryCodes.FRANCE]: 'Sony Music Entertainment France',
  [countryCodes.GERMANY]: 'Sony Music Entertainment Germany GmbH',
  [countryCodes.SWITZERLAND]: 'Sony Music Entertainment Switzerland GmbH',
  [countryCodes.AUSTRIA]: 'Sony Music Entertainment Austria GmbH',
  [countryCodes.HUNGARY]: 'Sony Music Entertainment Hungary Ltd',
  [countryCodes.IRELAND]: 'Sony Music Entertainment Ireland Limited',
  [countryCodes.ITALY]: 'Sony Music Entertainment Italy S.p.A.',
  [countryCodes.NORWAY]: 'Sony Music Entertainment Norway AS',
  [countryCodes.POLAND]: 'Sony Music Entertainment Poland Sp. z o. o.',
  [countryCodes.PORTUGAL]:
    'Sony Music Entertainment Portugal – Sociedade Unipessoal, Lda.',
  [countryCodes.SPAIN]: 'Sony Music Entertainment España S.L.',
  [countryCodes.SWEDEN]: 'Sony Music Entertainment Sweden AB',
  [countryCodes.UNITED_KINGDOM]: 'Sony Music Entertainment UK Ltd.',
  [countryCodes.ARGENTINA]: 'Sony Music Entertainment Argentina S.A.',
  [countryCodes.BRAZIL]: 'Sony Music Entertainment Brasil Ltda',
  [countryCodes.COLOMBIA]: 'Sony Music Entertainment Colombia S.A.',
  [countryCodes.PERU]: 'Sony Music Entertainment Perú S.A.',
  [countryCodes.MEXICO]: 'Sony Music Entertainment México, S.A. de C.V.',
  [countryCodes.SOUTH_AFRICA]: 'Sony Music Entertainment Africa (Pty) Ltd.',
  [countryCodes.AUSTRALIA]: 'Sony Music Entertainment Australia Pty Limited',
  [countryCodes.NEW_ZEALAND]: 'Sony Music Entertainment New Zealand Limited',
  [countryCodes.CHINA]: 'Sony Music Entertainment China',
  [countryCodes.HONG_KONG]: 'Sony Music Entertainment Hong Kong Ltd.',
  [countryCodes.INDIA]: 'Sony Music Entertainment India Private Limited',
  [countryCodes.INDONESIA]: 'Sony Music Entertainment Indonesia',
  [countryCodes.MALAYSIA]: 'Sony Music Entertainment Malaysia Sdn Bhd',
  [countryCodes.PHILIPPINES]: 'Sony Music Entertainment Philippines Inc.',
  [countryCodes.SINGAPORE]: 'Sony Music Entertainment Singapore Pte Ltd.',
  [countryCodes.KOREA]: 'Sony Music Entertainment Korea Inc.',
  [countryCodes.TAIWAN]: 'Sony Music Taiwan',
  [countryCodes.THAILAND]:
    'Sony Music Entertainment Operating (Thailand) Co. Ltd.',
  [countryCodes.VIETNAM]: 'Sony Music Entertainment Vietnam Limited',
  [countryCodes.CHILE]: 'Sony Music Entertainment Chile S.A.',
  [countryCodes.URUGUAY]: 'Sony Music Entertainment Chile S.A.',

  // middle east countries
  [countryCodes.IRAQ]: 'Sony Music Entertainment Middle East FZ-LLC',
  [countryCodes.IRAN]: 'Sony Music Entertainment Middle East FZ-LLC',
  [countryCodes.SAUDI_ARABIA]: 'Sony Music Entertainment Middle East FZ-LLC',
  [countryCodes.KUWAIT]: 'Sony Music Entertainment Middle East FZ-LLC',
  [countryCodes.OMAN]: 'Sony Music Entertainment Middle East FZ-LLC',
  [countryCodes.YEMEN]: 'Sony Music Entertainment Middle East FZ-LLC',
  [countryCodes.JORDAN]: 'Sony Music Entertainment Middle East FZ-LLC',
  [countryCodes.BAHRAIN]: 'Sony Music Entertainment Middle East FZ-LLC',
  [countryCodes.QATAR]: 'Sony Music Entertainment Middle East FZ-LLC',
  [countryCodes.SYRIAN_ARAB_REPUBLIC]:
    'Sony Music Entertainment Middle East FZ-LLC',
  [countryCodes.LEBANON]: 'Sony Music Entertainment Middle East FZ-LLC',
  [countryCodes.UNITED_ARAB_EMIRATES]:
    'Sony Music Entertainment Middle East FZ-LLC',
  [countryCodes.EGYPT]: 'Sony Music Entertainment Middle East FZ-LLC',
  [countryCodes.SUDAN]: 'Sony Music Entertainment Middle East FZ-LLC',
  [countryCodes.SOUTH_SUDAN]: 'Sony Music Entertainment Middle East FZ-LLC',
};

export const getDefaultMarketingConsent = (
  countryCode: MarketingConsentCountry
): MarketingConsent => {
  // user opted-in to entire consent by default
  const consent: MarketingConsent = {
    emailMarketing: true,
    marketingProfiling: true,
    advertisingProfiling: true,
    analyticsProfiling: true,
    dataSharing: true,
    ipLocationBasedMarketing: true,
  };

  for (const key in MARKETING_OPT_IN_CONFIG) {
    const optInCountries = MARKETING_OPT_IN_CONFIG[key];

    // if we found this country in opt-in config
    // it means user opted-out by default
    if (optInCountries.includes(countryCode)) {
      consent[key] = false;
    }
  }

  if (NO_IP_LOCATION_BASED_MARKETING.includes(countryCode)) {
    consent.ipLocationBasedMarketing = false;
  }

  return consent;
};

export const isMarketingConsentCountry = (
  country: string
): country is MarketingConsentCountry =>
  MARKETING_CONSENT_COUNTIES.some((c) => c === country);

export const getMarketingConsentCountry = (country: string) =>
  // default marketing consent country is UK as the strictest one
  isMarketingConsentCountry(country) ? country : countryCodes.UNITED_KINGDOM;

export const isDoubleOptInCountry = (country: string): boolean => {
  const countryToCheck = getMarketingConsentCountry(country);

  return Boolean(
    MARKETING_DOUBLE_OPT_IN_CONFIG.emailMarketing?.includes(countryToCheck)
  );
};

export const isMarketingConsentRequired = (country: string): boolean => {
  const countryToCheck = getMarketingConsentCountry(country);

  return !MARKETING_CONSENT_COUNTRIES_WITHOUT_OPT_IN.includes(countryToCheck);
};

export const getMarketingConsentPayload = (
  country: string,
  userSelection: Partial<MarketingConsent>
): MarketingConsent => {
  const marketingConsentCountry = getMarketingConsentCountry(country);

  // merge default values with user's
  // cause we might ask partial consent
  return {
    ...getDefaultMarketingConsent(marketingConsentCountry),
    ...removeUndefinedKeys(userSelection),
  };
};

export const getLegalName = (country: MarketingConsentCountry): string =>
  COUNTRY_TO_LEGAL_NAME[country];
